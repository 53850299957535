import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

import { LAYOUT_CONFIG } from '../constants';
import { BreakPoints, BreakPointsType } from '../types/layout';

type Subscriber = () => void;

const subscribers = new Set<Subscriber>();

interface ResponsiveConfig {
  breakpoints?: BreakPointsType;
  delay?: number;
}

type ResponsiveInfo = Record<BreakPoints, boolean>;

let info: ResponsiveInfo;

// match with Ant Design
const defaultBreakpoints: BreakPointsType = LAYOUT_CONFIG.BREAK_POINTS;

let responsiveConfig: ResponsiveConfig = {
  breakpoints: defaultBreakpoints,
  delay: 200,
};

function calculate() {
  const width = typeof window !== 'undefined' ? window.innerWidth : 0;
  const newInfo = {} as ResponsiveInfo;
  let shouldUpdate = false;
  const { breakpoints } = responsiveConfig;
  if (!breakpoints) {
    return;
  }
  for (const key of Object.keys(breakpoints)) {
    newInfo[key as BreakPoints] = width >= breakpoints[key as BreakPoints];
    if (newInfo[key as BreakPoints] !== info[key as BreakPoints]) {
      shouldUpdate = true;
    }
  }
  if (shouldUpdate) {
    info = newInfo;
  }
}

function init() {
  if (info) {
    return;
  }
  info = {} as ResponsiveInfo;
  calculate();
  if (typeof window !== 'undefined') {
    window.addEventListener(
      'resize',
      throttle(() => {
        const oldInfo = info;
        calculate();
        if (oldInfo === info) {
          return;
        }
        for (const subscriber of subscribers) {
          subscriber();
        }
      }, responsiveConfig.delay)
    );
  }
}

export function configResponsive(config: ResponsiveConfig) {
  responsiveConfig = {
    ...responsiveConfig,
    ...config,
  };
  if (info) {
    calculate();
  }
}

export function useResponsive() {
  init();
  const [state, setState] = useState<ResponsiveInfo>(info);

  useEffect(() => {
    const subscriber = () => {
      setState(info);
    };
    subscribers.add(subscriber);
    return () => {
      subscribers.delete(subscriber);
    };
  }, []);

  return state;
}
